import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../services/auth/authentication.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardGuard implements CanActivate {
  constructor(private auth: AuthenticationService, private router: Router) { }

  async canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ) {
    const promise = this.auth.isAuthenticated();
    promise.then(auth => {
      if (!auth) {
        this.router.navigate(['/login']);
      }
      
      if (state.url === '/login' && auth) {
        this.router.navigate(['/admin']);
      }

    })
    return promise;
  }
  
}
