import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { take } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  baseURL: string = "/api/auth";

  public isAuthorized = false;
  private madRequest: Promise<boolean>;
  private madeRequestStatic: boolean;

  constructor(private http: HttpClient, private router: Router) {
    this.madRequest = new Promise((res, rej) => {
      http
        .get(this.baseURL + "/is-authenticated")
        .pipe(take(1))
        .subscribe((auth) => {
          // @ts-ignore
          if (auth.data) {
            this.isAuthorized = true;
            this.madeRequestStatic = true;
            res(true);
          } else {
            this.madeRequestStatic = true;
            res(false);
          }
        });
    });
  }

  public login(username: string, password: string): Observable<any> {
    return this.http.post(this.baseURL, {
      username: username,
      password: password,
    });
  }

  public async isAuthenticated(): Promise<boolean> {
    if (this.madeRequestStatic) {
      return new Promise((res, rej) => {
        res(this.isAuthorized);
      });
    } else {
      return new Promise((res, rej) => {
        this.madRequest.then(data => {
          res(this.isAuthorized);
        })
      });
    }
  }

  public unAuthorizes() {
    this.isAuthorized = false;
    this.http.delete(this.baseURL + "/logout").pipe(take(1)).subscribe((auth) => {
      this.router.navigate(['/home']);
    });
  }
}
